@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?hn757u');
    src:  url('fonts/icomoon.eot?hn757u#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?hn757u') format('truetype'),
      url('fonts/icomoon.woff?hn757u') format('woff'),
      url('fonts/icomoon.svg?hn757u#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-action-icon:before {
    content: "\e900";
  }
  .icon-arrow-down:before {
    content: "\e901";
  }
  .icon-arrow-left:before {
    content: "\e902";
  }
  .icon-back-icon:before {
    content: "\e903";
  }
  .icon-cross-icon:before {
    content: "\e904";
  }
  .icon-delete-icon:before {
    content: "\e905";
  }
  .icon-export-icon:before {
    content: "\e906";
  }
  .icon-filter-icon:before {
    content: "\e907";
  }
  .icon-search-icon:before {
    content: "\e908";
  }
  .icon-sort-icon:before {
    content: "\e909";
  }
  