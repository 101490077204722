@import "pages/variables";
@import "pages/icons";
@import "pages/base";
@import "pages/button";
@import "pages/form";
@import "pages/login";
@import "pages/home";
@import "pages/header";
@import "pages/modal";
@import "pages/table";

.Toastify__toast--success {
  background-color: #4caf50 !important;
  color: white;
}

.Toastify__toast--error {
  background-color: #f44336 !important;
  color: white;
}

.Toastify__toast--info {
  background-color: #2196f3 !important;
  color: white;
}

.Toastify__toast--warning {
  background-color: #ff9800 !important;
  color: white;
}

.Toastify__close-button {
  color: white !important;
}

.Toastify__toast-container--top-center {
  top: 1rem;
  width: 100%;
  max-width: 500px;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  pointer-events: auto !important;
}
