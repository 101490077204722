* {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	font-family: $primary-font;
}
ul, h1, h2, h3, h4, h5, h6, p {
	margin: 0px;
	text-rendering: auto;
}

ul, ol, li {
	list-style-type: none;
	padding: 0;
}

html, body {
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	font-size: 14px;
	line-height: normal;
	-webkit-tap-highlight-color: transparent !important;
	color: $primary-text;
}

*:before, *:after {
	-webkit-tap-highlight-color: transparent !important;
}

.btn:focus {
	outline: none;
}
body,
ul,
figure {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
