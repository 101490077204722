.input-group, .input-error{
  margin-bottom: 30px;
  position: relative;
  .form-control{
    padding: 16px 20px 18px;
    border-radius: 100px;
    border: 1px solid #E9E9E9;
    height: 52px;
    &:focus, &:active{
      border: 1px solid #E9E9E9;
      box-shadow: none;
    }
  }
  .error{
    font-size: 12px;
    text-align: end;
    color: $primary-theme;
    display: block;
    margin-top: 6px;
    position: absolute;
    right: 8px;
  }
  .input-with-icon{
    position: relative;
    width: 100%;
    .form-control{
      padding-right: 64px;
    }
    .input-icon{
      position: absolute;
      right: 20px;
      top: 17px;
      font-size: 12px;
      font-weight: 700;
      color: $primary-theme;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .input-note{
    font-size: 12px;
    line-height: 120%; /* 14.4px */
    margin-top: 6px;
    color: #2C2B2B;
    text-align: start;
    margin-left: 8px !important;
  }
}
.input-error{
  .form-control{
    border-color: $error-border;
  }
}
input{
  &::placeholder {
    color: $text-grey !important;
    font-size: 14px !important;
  }
}

.custom-radio{
  display: flex;
  gap: 6px;
  .form-check{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding-left: 0;
    margin-bottom: 0;
    .form-check-input{
      width: 0;
      height: 0;
      margin-left: 0;
      display: none;
      &:checked{
        + .form-check-label{
          background-color: #FFF3F3;
          border-color: #FFF3F3;
        }
      }
      
    }
    .form-check-label{
      border: 1.2px solid $primary-text;
      padding: 8px 16px;
      border-radius: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%; /* 21px */
      cursor: pointer;
    }
  }
}
