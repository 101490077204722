.signin-container{
  display: flex;
  max-width: 100%;
  height: 100vh;
  padding: 40px;
  .login-banner{
    width: 709px;
    position: relative;
    border-radius: 30px;
    @media (min-width: 1280px) {
      width: 50%;
    }
    .bg-img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
    .logo{
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .signin-form-container{
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-form-card{
      width: 350px;
      text-align: center;
      .login-form{
        margin-top: 30px;
      }
      .form-title{
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        margin: 0 auto 10px;
        display: inline-block;
      }
      .form-subtitle{
        font-weight: 300;
        color: #2C2B2B;
        margin: 0 auto;
        text-align: center;
        max-width: 268px;
        &.reset-subtitle{
          max-width: 100%;
        }
      }
    }
  }
  .forgotPwd-link{
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2.76px;
    text-transform: uppercase;
    margin-top: 36px;
    text-align: center;
    a{
      color: $primary-text;
      text-decoration: none;
    }
  }
}