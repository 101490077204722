@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

$primary-font: "Poppins", sans-serif;

$primary-text: #2C2B2B;
$text-grey: #797979;

$primary-theme: #EC2127;
$error-border: #FF6060;
$text-success: #3DCBB1;
