.admin-body-container {
  padding-bottom: 45px;
  .container-fluid {
    padding-left: 55px;
    padding-right: 55px;
    max-width: 1450px;
  }
  .table-layout-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-blank-col {
      width: 248px;
    }
    .table-filter-row {
      display: flex;
      gap: 20px;
      .filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 35px;
        border-radius: 8px;
        border: 1px solid $primary-text;
        font-size: 16px;
        font-weight: 200;
        line-height: 25px;
        margin-left: 20px;
        color: $primary-text;
        text-transform: none;
        letter-spacing: normal;
        cursor: pointer;
        span{
          font-size: 17.5px;
          color: $primary-text;
          margin-right: 10px;
        }
        &:hover{
          border-color: $primary-theme;
          color: $primary-theme;
          span{
            color: $primary-theme;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        img {
          margin-right: 10px;
        }
        &.active{
          position: relative;
          &::after{
            content: '';
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            border-radius: 50%;
            background-color: $primary-theme;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
      .action-dropdown {
        min-width: 190px;
        .dropdown-item {
          padding: 17px 18px;
          color: $primary-text;
          img {
            margin-right: 7px;
          }
        }
      }
    }
  }
}
.search-card {
  border-radius: 31px;
  background: #fff9f9;
  padding: 10px 16px 8px;
  width: 354px;
  height: 40px;
  flex-shrink: 0;
  label {
    display: flex;
  }
  .search-icon {
    margin-right: 15px;
  }
  .input-search {
    background-color: transparent;
    border: 0;
    outline: none;
    width: 100%;
    padding: 0;
  }
}
.text-success {
  color: $text-success !important;
}
.text-danger {
  color: $error-border !important;
}

.user-detail-layout {
  .user-detail-back {
    .back-btn {
      display: inline-flex;
      align-items: center;
      .back-icon{
        width: 58px;
        height: 58px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid #E9E9E9;
        margin-right: 20px;
        cursor: pointer;
        span{
          font-size: 25px;
          color: $primary-text;
        }
        &:hover{
          border-color: $primary-theme;
          transition: all .3s ease;
          span{
            color: $primary-theme;
            transition: all .2s ease;
          }
        }
      }
      span {
        font-size: 30px;
        font-weight: 600;
      }
    }
  }
  .user-detail-head {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    .user-detail-info {
      display: flex;
      .user-img {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .user-info-list {
      display: flex;
      + .user-info-list {
        margin-top: 26px;
        .info-label {
          margin-bottom: 10px;
        }
      }
      .user-info-col {
        &:not(:first-child) {
          margin-left: 90px;
        }
        .info-label {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 4px;
        }
        .info-data {
          font-size: 16px;
          font-weight: 500;
        }
        .user-notes-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .notes-item {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px; /* 137.5% */
            letter-spacing: -0.272px;
            display: inline-flex;
            padding: 10px 26px;
            justify-content: center;
            align-items: center;
            border-radius: 9px;
            background: #fff3f3;
          }
        }
      }
    }
    .user-status-btn {
      .btn {
        letter-spacing: 2.76px;
        height: 56px;
        padding: 12px 30px;
        white-space: nowrap;
      }
    }
  }
  .user-char-row {
    display: flex;
    gap: 20px 65px;
    margin-top: 80px;
    .user-char-col {
      .char-card-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
      }
    }
    .user-char-card {
      width: 464px;
      flex-shrink: 0;
      border-radius: 20px;
      background: #fff3f3;
      padding: 20px;
      display: flex;
      position: relative;
      .delete-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
      .char-img {
        width: 135px;
        height: 161px;
        flex-shrink: 0;
        border-radius: 10px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 10px;
          background-color: #fff;
        }
      }
      .char-info-row {
        display: flex;
        gap: 0 30px;
      }
      .char-info-col {
        margin-bottom: 20px;
        .char-info-label {
          font-size: 12px;
          font-weight: 300;
          margin-bottom: 6px;
          white-space: nowrap;
        }
        .char-info-data {
          font-weight: 500;
        }
      }
      &.no-char-data{
        height: 281px;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin: 100px auto;
        .no-char-col{
          text-align: center;
          max-width: 270px;
          .no-char-img{
            width: 80px;
            height: 80px;
            flex-shrink: 0;
            border-radius: 50%;
            background: #F3F3F3;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
          }
          .no-char-title{
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            margin-top: 20px;
          }
          .no-char-subtitle{
            font-weight: 300;
            opacity: 0.9;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

// Loader
.home-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  // margin: 8px;
  border: 4px solid $primary-theme;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-theme transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-result-wrap{
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no-data-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
  }
  .no-data-subtitle{
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    opacity: 0.9;
    width: 285px;
    margin: 10px auto 0;
    text-align: center;
  }
}
