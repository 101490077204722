.modal{
  &.modal-confirm{
    .modal-dialog{
      width: 331px;
      .modal-content{
        border: 0;
        border-radius: 20px;
      }
      .modal-body{
        padding: 34px 60px;
        text-align: center;
        .modal-title{
          font-size: 22px;
          font-weight: 500;
          line-height: 118%; /* 25.96px */
          letter-spacing: -0.418px;
        }
        .modal-subtitle{
          font-size: 16px;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
          margin-top: 11px;
        }
        .modal-btn-row{
          margin-top: 20px;
          display: flex;
          flex-direction: column;
        }
      }
    }
    &.char-modal{
      .modal-dialog{
        width: 391px;
        .modal-body{
          padding: 44px 60px;
        }
      }
    }
  }
  &.modal-filter{
    .modal-dialog{
      width: 400px;
      .modal-content{
        border: 0;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.11);
        .modal-body{
          padding: 30px 20px;
        }
        .filter-title{
          font-size: 20px;
          font-weight: 275;
          line-height: 118%; /* 23.6px */
          letter-spacing: 1.4px;
        }
        .filter-form{
          margin: 10px 0 30px;
          .zip-code-filter{
            border-radius: 20px;
            background-color: #E9E9E9;
            .input-group{
              margin-bottom: 0;
              .dropdown-toggle{
                background-color: transparent;
                border: 0;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                opacity: 0.8;
                color: $text-grey;
                letter-spacing: normal;
                text-transform: none;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                &::after{
                  border: 0;
                  width: 24px;
                  height: 24px;
                  flex-shrink: 0;
                  background-image: url('../../images/arrow-down.svg');
                  background-size: 24px;
                  background-repeat: no-repeat;
                }
              }
            }
            .zip-codes-container{
              padding: 0 20px;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              gap: 6px;
              .zip-badge{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
                padding: 7px 15px;
                background-color: #fff !important;
                border-radius: 14px !important;
                color: $primary-text;
                .cross-btn{
                  background-image: url('../../images/cross-icon.svg');
                  background-repeat: no-repeat;
                  background-size: 10px;
                  padding: 0;
                  width: 12px;
                  height: 12px;
                  margin: 0 0 0 13px;
                }
                &:last-child{
                  margin-bottom: 20px;
                }
              }
            }
            .dropdown-menu{
              background-color: #fff;
              width: 360px;
              border-radius: 20px;
              border: 0;
              overflow: hidden;
              padding: 30px 12px 22px 0;
              box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.07);
              .filter-items-wrap{
                height: 210px;
                overflow-y: auto;
                background-color: #fff;
                // Custom Scrollbar
                &::-webkit-scrollbar {
                  width: 4px;
                }
                &::-webkit-scrollbar-track {
                  background: transparent;
                }
                &::-webkit-scrollbar-thumb {
                  background: #E9E9E9;
                  border-radius: 10px;
                }
              }
              .dropdown-item{
                padding: 0 30px;
                margin-bottom: 13px;
                background-color: transparent;
                color: $primary-text;
                // &:first-child{
                //   margin-top: 30px;
                // }
                &:last-child{
                  margin-bottom: 0;
                }
                .form-check{
                  .form-check-input{
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    border-radius: 4.66px;
                    border: 1px solid #E9E9E9;
                    &:checked{
                      background-color: #EC2127;
                      border-color: #EC2127;
                      background-size: 12px;
                    }
                  }
                  .form-check-label{
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 150%;
                    margin-left: 7px;
                  }
                }
                &.disabled{
                  .form-check-label{
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
        .filter-btns{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .clear-btn{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
            margin-right: 20px;
          }
        }
      }
    }
  }
  &.modal-description{
    .modal-dialog{
      width: 409px;
      .modal-body{
        padding: 30px;
      }
      .description-title{
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2.76px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .detail-img{
        width: 250px;
        height: 250px;
        flex-shrink: 0;
        margin-bottom: 18px;
        border: 1px solid #e8e8e8;
        border-radius: 17px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 17px;
        }
      }
      .description-detail{
        font-weight: 300;
      }
    }
  }
}
