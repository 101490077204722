.admin-navbar {
  padding: 40px 0;
  .container-fluid {
    padding-left: 55px;
    padding-right: 55px;
    max-width: 1450px;
  }
  .navbar-brand {
    padding: 0;
    img {
      height: 45px;
      width: auto;
    }
  }
  .navbar-nav {
    .nav-link {
      padding: 2px 0;
      margin: 0 22px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 2.76px;
      text-transform: uppercase;
      color: #2c2b2b;
      &.active {
        border-bottom: 1.2px solid $primary-theme;
      }
    }
  }
  .admin-profile {
    width: 170px;
    text-align: end;
    .admin-profile-btn {
      background-color: transparent;
      padding: 0;
      height: auto;
      min-width: auto;
      display: flex;
      align-items: center;
      margin-left: auto;
      &::after {
        border-top: 5px solid $primary-text;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin-left: 6px;
      }
    }
    .profile-dropdown {
      border-radius: 26px;
      background: #fff;
      box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.07);
      border: 0;
      padding: 20px 25px 10px;
      .dropdown-item {
        padding: 10px 0;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2.76px;
        text-transform: uppercase;
        background-color: transparent;
        color: $primary-text;
        &.signout-btn {
          font-size: 14px;
          font-weight: 275;
          color: $error-border;
          text-transform: unset;
          letter-spacing: normal;
        }
      }
    }
  }
}
