.admin-table-wrap {
  margin-top: 50px;
  table {
    width: 100%;
    border-spacing: 0 10px;
    border-collapse: separate;
  }
  thead {
    tr {
      th {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2.16px;
        padding: 0 15px;
        .th-sort {
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            width: 9px;
            height: 9px;
            background-image: url("../../images/sort-icon.svg");
            background-size: 9px;
            position: absolute;
            right: -14px;
            top: 4px;
          }
        }
      }
    }
  }
  tbody {
    tr {
      height: 90px;
      border-radius: 15px;
      background: #fff9f9;
      td {
        font-size: 14px;
        line-height: normal;
        font-weight: 300;
        padding: 10px 15px;
        &:first-child {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }
        &:last-child {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }
        .user-profile {
          display: flex;
          align-items: center;
          .user-img {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 50%;
            margin-right: 14px;
            box-shadow: 0px 1.898px 4.176px 0px rgba(0, 0, 0, 0.1);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .user-name {
            font-weight: 700;
            cursor: pointer;
          }
        }
        &.text-success {
          font-weight: 500;
        }
        &.text-underline {
          font-weight: 400;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.wid-5 {
  width: 5%;
}
.wid-10 {
  width: 10%;
}
.wid-15 {
  width: 15%;
}
.wid-20 {
  width: 20%;
}
.wid-25 {
  width: 25%;
}
.wid-30 {
  width: 30%;
}
.wid-35 {
  width: 35%;
}
.wid-40 {
  width: 40%;
}

// Pagination style
.pagination {
  margin-top: 54px;
  justify-content: flex-end;
  .page-item {
    margin: 0 3px;
    .page-link {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 4px;
      color: $primary-text;
      font-weight: 300;
      padding: 2px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e9e9e9;
      border: 0;
      box-shadow: none;
    }
    &.active {
      .page-link {
        background-color: $primary-theme;
        color: #fff;
      }
    }
    &.page-prev {
      .page-link {
        background-image: url("../../images/arrow-left.svg");
        background-repeat: no-repeat;
        background-size: 6px 10px;
        background-position: center;
      }
      span {
        display: none;
      }
    }
    &.page-next {
      @extend .page-prev;
      .page-link {
        transform: rotate(180deg);
      }
    }
  }
}
.table-dropdown {
  .action-btn {
    background-color: transparent !important;
    padding: 0;
    height: auto;
    min-width: auto;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-radius: 0;
    border: 0;
    &::after {
      display: none;
    }
  }
  .action-dropdown {
    border: 0;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.11);
    padding: 0;
    min-width: 212px;
    .dropdown-item {
      padding: 20px;
      background-color: transparent;
      font-size: 14px;
    }
  }
}
