.btn {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.76px;
  text-transform: uppercase;
  &.btn-primary {
    background-color: $primary-theme;
    color: #fff;
    height: 56px;
    padding: 10px 30px;
    border-radius: 100px;
    border: 0;
    min-width: 168px;
    &:focus,
    &:active {
      background-color: $primary-theme;
    }
    &:hover{
      scale: 1.05;
      transition: all .3s ease;
    }
  }
  &.btn-danger {
    height: 52px;
    padding-left: 45px;
    border-radius: 100px;
    background-color: $primary-theme;
    padding-right: 45px;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.72px;
    &:hover{
      scale: 1.05;
      transition: all .3s ease;
    }
  }
  &.btn-transparent {
    font-weight: 600;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.72px;
    background-color: transparent;
    height: auto;
    color: $primary-text;
    padding: 0;
    margin-top: 30px;
    border: 0;
  }
  &.btn-success {
    height: 52px;
    padding-left: 45px;
    border-radius: 100px;
    background-color: $text-success;
    padding-right: 45px;
    line-height: 140%;
    letter-spacing: 0.72px;
    border-color: #fff;
  }
}
